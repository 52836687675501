import * as types from "./types"

export const invoicesRequest = (payload) => ({ type: types.INVOICES_REQUEST, payload })
export const invoicesError = (payload) => ({ type: types.INVOICES_ERROR, payload })
export const invoicesSuccess = (payload) => ({ type: types.INVOICES_SUCCESS, payload })

export const invoicesReportRequest = (payload) => ({ type: types.INVOICES_REPORT_REQUEST, payload })
export const invoicesReportError = (payload) => ({ type: types.INVOICES_REPORT_ERROR, payload })
export const invoicesReportSuccess = (payload) => ({ type: types.INVOICES_REPORT_SUCCESS, payload })

export const invoiceRequest = (payload) => ({ type: types.INVOICE_REQUEST, payload })
export const invoiceSuccess = (payload) => ({ type: types.INVOICE_SUCCESS, payload })

export const createInvoiceRequest = (payload) => ({ type: types.CREATE_INVOICE_REQUEST, payload })
export const createInvoiceSuccess = (payload) => ({ type: types.CREATE_INVOICE_SUCCESS, payload })

export const updateInvoiceRequest = (payload) => ({ type: types.UPDATE_INVOICE_REQUEST, payload })
export const updateInvoiceSuccess = (payload) => ({ type: types.UPDATE_INVOICE_SUCCESS, payload })

export const confirmInvoiceRequest = (payload) => ({ type: types.CONFIRM_INVOICE_REQUEST, payload })
export const confirmInvoiceSuccess = (payload) => ({ type: types.CONFIRM_INVOICE_SUCCESS, payload })

export const processInvoiceRequest = (payload) => ({ type: types.PROCESS_INVOICE_REQUEST, payload })
export const processInvoiceSuccess = (payload) => ({ type: types.PROCESS_INVOICE_SUCCESS, payload })

export const declineInvoiceRequest = (payload) => ({ type: types.DECLINE_INVOICE_REQUEST, payload })
export const declineInvoiceSuccess = (payload) => ({ type: types.DECLINE_INVOICE_SUCCESS, payload })



