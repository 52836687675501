export const INVOICES_REQUEST = "@@invoice/INVOICES_REQUEST";
export const INVOICES_ERROR = "@@invoice/INVOICES_ERROR";
export const INVOICES_SUCCESS = "@@invoice/INVOICES_SUCCESS";

export const INVOICES_REPORT_REQUEST = "@@invoice/INVOICES_REPORT_REQUEST";
export const INVOICES_REPORT_ERROR = "@@invoice/INVOICES_REPORT_ERROR";
export const INVOICES_REPORT_SUCCESS = "@@invoice/INVOICES_REPORT_SUCCESS";

export const INVOICE_REQUEST = "@@invoice/INVOICE_REQUEST";
export const INVOICE_SUCCESS = "@@invoice/INVOICE_SUCCESS";

export const CREATE_INVOICE_REQUEST = "@@invoice/CREATE_INVOICE_REQUEST";
export const CREATE_INVOICE_SUCCESS = "@@invoice/CREATE_INVOICE_SUCCESS";

export const UPDATE_INVOICE_REQUEST = "@@invoice/UPDATE_INVOICE_REQUEST";
export const UPDATE_INVOICE_SUCCESS = "@@invoice/UPDATE_INVOICE_SUCCESS";

export const CONFIRM_INVOICE_REQUEST = "@@invoice/CONFIRM_INVOICE_REQUEST";
export const CONFIRM_INVOICE_SUCCESS = "@@invoice/CONFIRM_INVOICE_SUCCESS";

export const PROCESS_INVOICE_REQUEST = "@@invoice/PROCESS_INVOICE_REQUEST";
export const PROCESS_INVOICE_SUCCESS = "@@invoice/PROCESS_INVOICE_SUCCESS";

export const DECLINE_INVOICE_REQUEST = "@@invoice/DECLINE_INVOICE_REQUEST";
export const DECLINE_INVOICE_SUCCESS = "@@invoice/DECLINE_INVOICE_SUCCESS";

