export const initialState = {
  directors: {
    data: [],
    loading: false,
    totalCount: 0
  },

  report: {
    data: [],
    totalDeposit: 0,
    totalWithdraw: 0,
  },
  currentDirector: null
}

const directorsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "@@director/DIRECTORS_REQUEST":
      return {
        ...state,
        directors: {
          ...state.directors,
          loading: true,
        }
      };
    case "@@director/DIRECTORS_ERROR":
      return {
        ...state,
        directors: {
          ...state.directors,
          loading: false,
        }
      };
    case "@@director/DIRECTORS_SUCCESS":
      return {
        ...state,
        directors: {
          data: action.payload,
          totalCount: action.payload.totalCount,
          loading: false,
        }
        
      };
    case "@@director/DIRECTOR_REPORT_SUCCESS":
      return {
        ...state,
        report: {
          data: action.payload.reportData,
          depositAmount: action.payload.depositAmount,
          withdrawAmount: action.payload.withdrawAmount,
        },
        currentDirector: action.payload.director,       
      };

    case "@@director/CREATE_DIRECTOR_SUCCESS":
      return {
        ...state,
        directors: {
          ...state.directors,
          data: [        
            ...state.directors.data,
            action.payload,
          ],
        }
      };
    case "@@director/DELETE_DIRECTOR_SUCCESS":
      return {
        ...state,
        directors: {
          ...state.directors,
          data: state.directors.data.filter(director => director.id !== action.payload),
          totalCount: state.directors.totalCount - 1,
        },
      };
    default: {
      return state;
    }
  }

}

export default directorsReducer;