import axios from "axios";
import { all, fork, put, takeEvery } from "redux-saga/effects";
import { loginSuccess, profileError, logoutSuccess, profileSuccess, changePasswordSuccess } from "./actions";
import { LOGIN_REQUEST, PROFILE_REQUEST, SIGNUP_REQUEST, LOGOUT_REQUEST, CHANGE_PASSWORD_REQUEST } from "./types";
import setAuthorizationHeader from "../setAuthorizationHeader";
import { API_URL } from '../../config';
import history from '../../history';
import handleError from '../../helpers/errorHandler';
import store from '../../store';
import { showSnackbar } from '../snackbar/actions';


function* loginRequest(action) {
  try {
    const response = yield axios.post(`${API_URL}/auth/login/`, action.payload)
    localStorage.ACCESS_TOKEN = response.data.ACCESS_TOKEN;
    setAuthorizationHeader(response.data.ACCESS_TOKEN);
    yield put(loginSuccess(response.data));
  } catch (err) {
    if (err?.response?.status === 401){
      store.dispatch(showSnackbar('Incorrect login or password', 'error'))
      return false;
    }
    handleError(err);
  }
}

function* signupRequest(action) {
  try {
    const response = yield axios.post(`${API_URL}/players/signup/`, action.payload)
    window.location.href = 'https://t.me/champion_online_bot';
  } catch (err) {
    handleError(err);
  }
}

function* profileRequest(action) {
  try {
    if (localStorage.ACCESS_TOKEN) {
      setAuthorizationHeader(localStorage.ACCESS_TOKEN);
      const response = yield axios.get(`${API_URL}/auth/profile`);
      yield put(loginSuccess(response.data));
      yield put(profileSuccess(response.data));
      if(action.payload.href){
        history.push(action.payload.href);
      }
    }
  } catch (err) {
    yield put(profileError());
    handleError(err);
  }
}

function* logoutRequest() {
  yield put(logoutSuccess());
  localStorage.removeItem("ACCESS_TOKEN");
}

function* changePasswordRequest(action) {
  try {
    const response = yield axios.post(`${API_URL}/user/changepassword`, action.payload)

    yield put(changePasswordSuccess(response.data));
  } catch (err) {
    handleError(err);
  }
}



function* watchRequests() {
  yield takeEvery(LOGIN_REQUEST, loginRequest);
  yield takeEvery(PROFILE_REQUEST, profileRequest);
  yield takeEvery(SIGNUP_REQUEST, signupRequest);
  yield takeEvery(LOGOUT_REQUEST, logoutRequest);
  yield takeEvery(CHANGE_PASSWORD_REQUEST, changePasswordRequest);
}

function* gamesSaga() {
  yield all([fork(watchRequests)]);
}

export default gamesSaga;