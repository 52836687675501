import React from 'react'
import { Redirect } from 'react-router-dom'
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CInput,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupText,
  CRow
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { connect } from "react-redux"
import { signupRequest } from "../../store/auth/actions"
import InputMask from "react-input-mask";
import { useLocation } from "react-router-dom";
import history from 'src/history'

class Signup extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      phone: '',
      refCode: '',
    };

    this.onChange = this.onChange.bind(this);
  }


  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value })
  }

  setPhone = (val) => {
    this.setState({
      phone: val,
    });
  }

  componentDidMount(){
    const search = history.location.search;
    const ref = new URLSearchParams(search).get("ref");
    this.setState({
      refCode: ref,
    });
  }

  onSubmit = (e) => {
    e.preventDefault();
    const purePhone = this.state.phone.replace(/[-()]/g,'');
    this.props.signupRequest({ phone: purePhone, referralCode: this.state.refCode });
  }

  render() {
    const { isLogged } = this.props;
    return (
      isLogged ?
        <Redirect to="/" /> :
        < div className="c-app c-default-layout flex-row align-items-center" >
          <CContainer>
            <CRow className="justify-content-center">
              <CCol md="8">
                <CCardGroup>
                  <CCard className="p-4">
                    <CCardBody>
                      <CForm onSubmit={this.onSubmit}>
                        <h1>Регистрация</h1>
                        <p className="text-muted">Регистрация в ChampionBot</p>
                        <CInputGroup className="mb-3">
                          <CInputGroupPrepend>
                            <CInputGroupText>
                              <CIcon name="cil-phone" />
                            </CInputGroupText>
                          </CInputGroupPrepend>
                          <InputMask mask="+380(99)-999-99-99" onChange={this.onChange} id="phone" />
                        </CInputGroup>
                        <CRow>
                          <CCol xs="6">
                            <CButton color="primary" type="submit" className="px-4">Зарегистрироваться</CButton>
                          </CCol>
                        </CRow>
                      </CForm>
                    </CCardBody>
                  </CCard>
                </CCardGroup>
              </CCol>
            </CRow>
          </CContainer>
        </div >
    )
  }
}

export default connect((state) => ({ isLogged: state.auth.isAuthentificated }), { signupRequest })(Signup)
