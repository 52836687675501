import { showSnackbar } from "../store/snackbar/actions";
import store from "../store/configureStore";
import history from '../history';

const handleError = (err) => {
  const message = err?.response?.status === 401 ? 'Incorrect login or password' : err?.response?.data?.message || 'Unknown Error';

  if (err?.response?.status === 401){
    history.push('/login');
    return false;
  }

  store.dispatch(showSnackbar(message, 'error'));
}

export default handleError;