export const initialState = {
  currentSettings: {}
}

const settingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "@@settings/SETTINGS_SUCCESS":
      return {
        ...state,
        currentSettings: action.payload
      };

    case "@@settings/UPDATE_SETTINGS_SUCCESS":
      return {
        ...state,
        currentSettings: action.payload
      };
    default: {
      return state;
    }
  }

}

export default settingsReducer;