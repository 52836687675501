import axios from "axios";
import qs from "querystring";
import { all, fork, put, takeEvery } from "redux-saga/effects";
import { spendingsSuccess, createSpendingSuccess, spendingsReportSuccess } from "./actions";
import { SPENDINGS_REQUEST, CREATE_SPENDING_REQUEST, SPENDINGS_REPORT_REQUEST } from "./types";
import { API_URL } from '../../config';
import handleError from '../../helpers/errorHandler';

function* spendingsRequest(action) {
  try {
    const query = qs.stringify(action.payload.query);
    const response = yield axios.get(`${API_URL}/spendings/?${query}&${action.payload.outerFilters}`)
    yield put(spendingsSuccess(response.data));
  } catch (err) {
    handleError(err);
  }
}

function* createSpendingRequest(action) {
  try {
    const response = yield axios.post(`${API_URL}/spendings/create`, action.payload)
    yield put(createSpendingSuccess(response.data));
  } catch (err) {
    handleError(err);
  }
}

function* spendingsReportRequest(action) {
  try {
    const query = qs.stringify(action.payload);
    const response = yield axios.get(`${API_URL}/spendings/report?${query}`)
    yield put(spendingsReportSuccess(response.data));
  } catch (err) {
    handleError(err);
  }
}

function* watchRequests() {
  yield takeEvery(SPENDINGS_REQUEST, spendingsRequest);
  yield takeEvery(CREATE_SPENDING_REQUEST, createSpendingRequest);
  yield takeEvery(SPENDINGS_REPORT_REQUEST, spendingsReportRequest);
}

function* spendingsSaga() {
  yield all([fork(watchRequests)]);
}

export default spendingsSaga;