import axios from "axios";
import qs from "querystring";
import { all, fork, put, takeEvery } from "redux-saga/effects";
import { balanceHistorySuccess} from "./actions";
import { HISTORY_REQUEST} from "./types";
import { API_URL } from '../../config';import handleError from '../../helpers/errorHandler';
;

function* balanceHistoryRequest(action) {
  try {
    const queryString = qs.stringify(action.payload.query);
    const response = yield axios.post(`${API_URL}/players/history?${queryString}`, action.payload.filters)
    yield put(balanceHistorySuccess(response.data));
  } catch (err) {
    handleError(err);
  }
}


function* watchRequests() {
  yield takeEvery(HISTORY_REQUEST, balanceHistoryRequest);
}

function* balanceSaga() {
  yield all([fork(watchRequests)]);
}

export default balanceSaga;