import * as types from "./types"

export const bonusesRequest = (payload) => ({ type: types.BONUSES_REQUEST, payload })
export const bonusesError = (payload) => ({ type: types.BONUSES_ERROR, payload })
export const bonusesSuccess = (payload) => ({ type: types.BONUSES_SUCCESS, payload })

export const bonusesReportRequest = (payload) => ({ type: types.BONUSES_REPORT_REQUEST, payload })
export const bonusesReportError = (payload) => ({ type: types.BONUSES_REPORT_ERROR, payload })
export const bonusesReportSuccess = (payload) => ({ type: types.BONUSES_REPORT_SUCCESS, payload })




