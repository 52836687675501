import axios from "axios";
import qs from "querystring";
import { all, fork, put, takeEvery } from "redux-saga/effects";
import { bonusesSuccess, bonusesReportSuccess, bonusesError, bonusesReportError } from "./actions";
import { BONUSES_REQUEST, BONUSES_REPORT_REQUEST } from "./types";
import { API_URL } from '../../config';
import handleError from '../../helpers/errorHandler';

function* bonusesRequest(action) {
  try {
    const query = qs.stringify(action.payload.query);
    const response = yield axios.post(`${API_URL}/bonus/findAllByPlayerId?${query}`, action.payload.filters)
    yield put(bonusesSuccess(response.data));
  } catch (err) {
    yield put(bonusesError(err));
    handleError(err);
  }
}

function* bonusesReportRequest(action) {
  try {
    const query = qs.stringify(action.payload);
    const response = yield axios.get(`${API_URL}/bonus/report?${query}`)
    yield put(bonusesReportSuccess(response.data));
  } catch (err) {
    yield put(bonusesReportError(err));
    handleError(err);
  }
}

function* watchRequests() {
  yield takeEvery(BONUSES_REQUEST, bonusesRequest);
  yield takeEvery(BONUSES_REPORT_REQUEST, bonusesReportRequest);
}

function* bonusesSaga() {
  yield all([fork(watchRequests)]);
}

export default bonusesSaga;