import { combineReducers } from "redux";
import { all, fork } from "redux-saga/effects";

import authReducer from "./auth/reducer"
import authSaga from "./auth/sagas"

import userReducer from "./user/reducer"
import userSaga from "./user/sagas"

import playerReducer from "./player/reducer";
import playerSaga from "./player/sagas";

import invoiceReducer from "./invoice/reducer";
import invoiceSaga from "./invoice/sagas";

import settingsReducer from "./settings/reducer";
import settingsSaga from "./settings/sagas";

import codeReducer from "./code/reducer";
import codeSaga from "./code/sagas";

import balanceReducer from "./balance/reducer";
import balanceSaga from "./balance/sagas";

import bonusReducer from "./bonus/reducer";
import bonusSaga from "./bonus/sagas";

import spendingsReducer from './spendings/reducer';
import spendingsSaga from './spendings/sagas';

import mainReducer from "./app/reducer";

import snackbarReducer from "./snackbar/reducer"

import statsReducer from './stats/reducer';
import statsSaga from './stats/sagas';

import directorsReducer from './director/reducer';
import directorsSaga from './director/sagas';

const appReducer = combineReducers({
  auth: authReducer,
  player: playerReducer,
  invoice: invoiceReducer,
  settings: settingsReducer,
  code: codeReducer,
  balance: balanceReducer,
  bonus: bonusReducer,
  spendings: spendingsReducer,
  snackbar: snackbarReducer,
  app: mainReducer,
  stats: statsReducer,
  director: directorsReducer,
  user: userReducer,
});

export const initialState = () => {
  return {};
};

export const rootReducer = (state, action) => {
  return appReducer(state, action);
}

export function* rootSaga() {
  yield all([
    fork(authSaga),
    fork(playerSaga),
    fork(invoiceSaga),
    fork(settingsSaga),
    fork(codeSaga),
    fork(balanceSaga),
    fork(bonusSaga),
    fork(spendingsSaga),
    fork(statsSaga),
    fork(directorsSaga),
    fork(userSaga)
  ]);
}