export const DIRECTORS_REQUEST = "@@director/DIRECTORS_REQUEST";
export const DIRECTORS_ERROR = "@@director/DIRECTORS_ERROR";
export const DIRECTORS_SUCCESS = "@@director/DIRECTORS_SUCCESS";

export const CREATE_DIRECTOR_REQUEST = "@@director/CREATE_DIRECTOR_REQUEST";
export const CREATE_DIRECTOR_SUCCESS = "@@director/CREATE_DIRECTOR_SUCCESS";

export const DELETE_DIRECTOR_REQUEST = "@@director/DELETE_DIRECTOR_REQUEST";
export const DELETE_DIRECTOR_SUCCESS = "@@director/DELETE_DIRECTOR_SUCCESS";

export const DIRECTOR_REPORT_REQUEST = "@@director/DIRECTOR_REPORT_REQUEST";
export const DIRECTOR_REPORT_SUCCESS = "@@director/DIRECTOR_REPORT_SUCCESS";