import {
  CODES_REQUEST,
  CODES_SUCCESS,
  CODES_ERROR,
  CREATE_CODE_REQUEST,
  CREATE_CODE_SUCCESS,

} from "./types"

export const codesRequest = (payload) => ({ type: CODES_REQUEST, payload })
export const codesError = (payload) => ({ type: CODES_ERROR, payload })
export const codesSuccess = (payload) => ({ type: CODES_SUCCESS, payload })

export const createCodeRequest = (payload) => ({ type: CREATE_CODE_REQUEST, payload })
export const createCodeSuccess = (payload) => ({ type: CREATE_CODE_SUCCESS, payload })




