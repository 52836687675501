import * as types from './types';

export const showSnackbar = (message , snacknarType) => {
  return {
    type: types.SHOW_SNACKBAR,
    message,
    snacknarType
  };
};

export const hideSnackbar = () => {
  return {
    type: types.HIDE_SNACKBAR
  };
};