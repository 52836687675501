
export const initialState = {
  main: {
    playersLast24h: 0,
    depositsLast24h: 0,
    withdrawsLast24h: 0,
    spendingsLastMonth: 0,
  }
}

const statsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "@@stats/MAIN_STATS_SUCCESS":
      return {
        ...state,
        main: action.payload,
      };

    default: {
      return state;
    }
  }

}

export default statsReducer;