export const initialState = {
  codes: {
    data: [],
    totalCount: 0,
    pageSize: 10,
    loading: false,
  },
  
}

const invoicesReducer = (state = initialState, action) => {
  switch (action.type) {
    case "@@code/CODES_REQUEST":
      return {
        ...state,
        codes: {
          ...state.codes,
          loading: true,
        }
      };
    case "@@code/CODES_ERROR":
      return {
        ...state,
        codes: {
          ...state.codes,
          loading: false,
        }
      };
    case "@@code/CODES_SUCCESS":
      return {
        ...state,
        codes: {
          ...state.codes,
          data: action.payload.data,
          totalCount: action.payload.totalCount,
          loading: false,
        }
      };
    case "@@code/CREATE_CODE_SUCCESS":

      const parts = action.payload.number.match(/.{1,2}/g);
      const newNumber = parts.join("-");

      const code = {
        ...action.payload,
        amount: action.payload.amount / 100,
        number: newNumber,
      }

      const data = [
        code,
        ...state.codes.data
      ];

      return {
        ...state, 
          codes: {
            data: data.length > 10 ? data.slice(0, 10) : data,
            loading: false,
            totalCount: state.codes.totalCount + 1
        }
      };
    default: {
      return state;
    }
  }

}

export default invoicesReducer;