import axios from "axios";
import qs from "querystring";
import { all, fork, put, takeEvery } from "redux-saga/effects";
import { codesError, codesSuccess, createCodeSuccess } from "./actions";
import { CODES_REQUEST, CREATE_CODE_REQUEST } from "./types";
import { API_URL } from '../../config';
import { playerRequest } from '../player/actions';
import handleError from '../../helpers/errorHandler';

function* codesRequest(action) {
  try {
    const queryString = qs.stringify(action.payload.query);
    const response = yield axios.post(`${API_URL}/codes/getAllByPlayerId?${queryString}`, action.payload.filters)
    yield put(codesSuccess(response.data));
  } catch (err) {
    yield put(codesError(err))
    handleError(err);
  }
}

function* createCodeRequest(action) {
  try {
    const response = yield axios.post(`${API_URL}/codes/create`, action.payload)
    yield put(createCodeSuccess(response.data));
    yield put(playerRequest({playerId: action.payload.playerId}));
  } catch (err) {
    handleError(err);
  }
}

function* watchRequests() {
  yield takeEvery(CODES_REQUEST, codesRequest);
  yield takeEvery(CREATE_CODE_REQUEST, createCodeRequest);
}

function* codesSaga() {
  yield all([fork(watchRequests)]);
}

export default codesSaga;