import { compose, createStore, applyMiddleware } from "redux";
import { initialState, rootReducer, rootSaga } from "./rootReducer";
import { composeWithDevTools } from "redux-devtools-extension";
import createSagaMiddleware from "redux-saga"
import thunk from "redux-thunk";

function configureStore() {

  const composeEnhancers = process.env.NODE_ENV === "development" ? composeWithDevTools({}) : compose();

  const sagaMiddleware = createSagaMiddleware();

  const store = createStore(rootReducer, initialState(), composeEnhancers(applyMiddleware(thunk, sagaMiddleware)));
  sagaMiddleware.run(rootSaga);

  return store;
}

const storeConfigured = configureStore();

export default storeConfigured;

