import React, { Component } from 'react';
import { Router } from 'react-router-dom';
import RenderRoutes from "./routes/RenderRoutes";
import './scss/style.scss';
import history from './history';
import { connect } from 'react-redux';
import { GridLoader } from 'react-spinners';
import { MaterialSnackbar } from './reusable/snackbar/Snackbar';

const loading = (
  <div
  className={"container d-flex justify-content-center align-items-center"}
  style={{height: '100%', maxWidth: '100%', width: '100%', position: 'absolute'}}>
    <GridLoader
      size={50}
      margin={2}
      color={"#123abc"}
      loading={true}
    />
  </div>
)
class App extends Component {

  render() {
    const { preloader } = this.props;
    return (
      preloader ? loading :
      <React.Suspense fallback={loading}>
        <Router history={history}>
          <MaterialSnackbar />
          <RenderRoutes />
        </Router>
      </React.Suspense>
    );
  }
}

export default connect((state)=> ({preloader: state.auth.preloader}))(App);
