import React from 'react'
import { Redirect } from 'react-router-dom'
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CInput,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupText,
  CRow
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { connect } from "react-redux"
import { loginRequest } from "../../../store/auth/actions"

class Login extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      login: '',
      password: ''
    };

    this.onChange = this.onChange.bind(this);

  }


  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value })
  }

  onSubmit = (e) => {
    e.preventDefault();
    this.props.loginRequest({ login: this.state.login, password: this.state.password });
  }

  render() {
    const { isLogged } = this.props;
    return (
      isLogged ?
        <Redirect to="/" /> :
        < div className="c-app c-default-layout flex-row align-items-center" >
          <CContainer>
            <CRow className="justify-content-center">
              <CCol md="8">
                <CCardGroup>
                  <CCard className="p-4">
                    <CCardBody>
                      <CForm onSubmit={this.onSubmit}>
                        <h1>Войти</h1>
                        <p className="text-muted">Войдите в аккаунт</p>
                        <CInputGroup className="mb-3">
                          <CInputGroupPrepend>
                            <CInputGroupText>
                              <CIcon name="cil-user" />
                            </CInputGroupText>
                          </CInputGroupPrepend>
                          <CInput type="text" placeholder="Логин" autoComplete="username" id="login" onChange={this.onChange} />
                        </CInputGroup>
                        <CInputGroup className="mb-4">
                          <CInputGroupPrepend>
                            <CInputGroupText>
                              <CIcon name="cil-lock-locked" />
                            </CInputGroupText>
                          </CInputGroupPrepend>
                          <CInput type="password" placeholder="Пароль" id="password" autoComplete="current-password" onChange={this.onChange} />
                        </CInputGroup>
                        <CRow>
                          <CCol xs="6">
                            <CButton color="primary" type="submit" className="px-4">Войти</CButton>
                          </CCol>
                        </CRow>
                      </CForm>
                    </CCardBody>
                  </CCard>
                </CCardGroup>
              </CCol>
            </CRow>
          </CContainer>
        </div >
    )
  }
}

export default connect((state) => ({ isLogged: state.auth.isAuthentificated }), { loginRequest })(Login)
