import * as types from "./types"

export const spendingsRequest = (payload) => ({ type: types.SPENDINGS_REQUEST, payload })
export const spendingsError = (payload) => ({ type: types.SPENDINGS_ERROR, payload })
export const spendingsSuccess = (payload) => ({ type: types.SPENDINGS_SUCCESS, payload })

export const createSpendingRequest = (payload) => ({ type: types.CREATE_SPENDING_REQUEST, payload })
export const createSpendingError = (payload) => ({ type: types.CREATE_SPENDING_ERROR, payload })
export const createSpendingSuccess = (payload) => ({ type: types.CREATE_SPENDING_SUCCESS, payload })

export const spendingsReportRequest = (payload) => ({ type: types.SPENDINGS_REPORT_REQUEST, payload })
export const spendingsReportError = (payload) => ({ type: types.SPENDINGS_REPORT_ERROR, payload })
export const spendingsReportSuccess = (payload) => ({ type: types.SPENDINGS_REPORT_SUCCESS, payload })