import * as types from "./types"

export const usersRequest = (payload) => ({ type: types.USERS_REQUEST, payload })
export const usersError = (payload) => ({ type: types.USERS_ERROR, payload })
export const usersSuccess = (payload) => ({ type: types.USERS_SUCCESS, payload })

export const userRequest = (payload) => ({ type: types.USER_REQUEST, payload })
export const userSuccess = (payload) => ({ type: types.USER_SUCCESS, payload })

export const closeUpdating = (payload) => ({ type: types.CLOSE_UPDATING, payload })

export const createUserRequest = (payload) => ({ type: types.CREATE_USER_REQUEST, payload });
export const createUserSuccess = (payload) => ({ type: types.CREATE_USER_SUCCESS, payload });

export const updateUserRequest = (payload) => ({ type: types.UPDATE_USER_REQUEST, payload });
export const updateUserSuccess = (payload) => ({ type: types.UPDATE_USER_SUCCESS, payload });

export const deleteUserRequest = (payload) => ({ type: types.DELETE_USER_REQUEST, payload });
export const deleteUserSuccess = (payload) => ({ type: types.DELETE_USER_SUCCESS, payload });
