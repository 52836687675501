import { SETTINGS_REQUEST, SETTINGS_SUCCESS, UPDATE_SETTINGS_REQUEST, UPDATE_SETTINGS_SUCCESS } from "./types"

export const settingsRequest = () => ({ type: SETTINGS_REQUEST })
export const settingsSuccess = (payload) => ({ type: SETTINGS_SUCCESS, payload })

export const updateSettingsRequest = (payload) => ({ type: UPDATE_SETTINGS_REQUEST, payload })
export const updateSettingsSuccess = (payload) => ({ type: UPDATE_SETTINGS_SUCCESS, payload })



