export const initialState = {
  history: {
    data: [],
    totalCount: 0,
    pageSize: 10,
  },
  
}

const balanceReducer = (state = initialState, action) => {
  switch (action.type) {
    case "@@balance/HISTORY_SUCCESS":
      return {
        ...state,
        history: {
          ...state.history,
          data: action.payload.data,
          totalCount: action.payload.totalCount,
        }
      };
    default: {
      return state;
    }
  }

}

export default balanceReducer;