export const USERS_REQUEST = "@@user/USERS_REQUEST";
export const USERS_ERROR = "@@user/USERS_ERROR";
export const USERS_SUCCESS = "@@user/USERS_SUCCESS";

export const USER_REQUEST = "@@user/USER_REQUEST";
export const USER_SUCCESS = "@@user/USER_SUCCESS";

export const CLOSE_UPDATING = "@@user/CLOSE_UPDATING";

export const CREATE_USER_REQUEST = "@@user/CREATE_USER_REQUEST";
export const CREATE_USER_SUCCESS = "@@user/CREATE_USER_SUCCESS";

export const UPDATE_USER_REQUEST = "@@user/UPDATE_USER_REQUEST";
export const UPDATE_USER_SUCCESS = "@@user/UPDATE_USER_SUCCESS";

export const DELETE_USER_REQUEST = "@@user/DELETE_USER_REQUEST";
export const DELETE_USER_SUCCESS = "@@user/DELETE_USER_SUCCESS";