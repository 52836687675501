import axios from "axios";
import { all, fork, put, takeEvery } from "redux-saga/effects";
import {
  directorsSuccess,
  createDirectorSuccess,
  deleteDirectorSuccess,
  directorsError,
  directorReportSuccess,
} from "./actions";
import {
  DIRECTORS_REQUEST,
  CREATE_DIRECTOR_REQUEST,
  DELETE_DIRECTOR_REQUEST,
  DIRECTOR_REPORT_REQUEST,
} from "./types";
import { API_URL } from "../../config";
import handleError from '../../helpers/errorHandler';

function* directorsRequest(action) {
  try {
    const response = yield axios.get(`${API_URL}/directors`,);
    yield put(directorsSuccess(response.data));
  } catch (err) {
    yield put(directorsError(err));
    handleError(err);
  }
}

function* createDirectorRequest(action) {
  try {
    const response = yield axios.post(
      `${API_URL}/directors/create`,
      action.payload
    );
    yield put(createDirectorSuccess(response.data));
  } catch (err) {
    handleError(err);
  }
}

function* deleteDirectorRequest(action) {
  try {
    const response = yield axios.delete(
      `${API_URL}/directors/delete?id=${action.payload.directorId}`
    );
    yield put(deleteDirectorSuccess(response.data));
  } catch (err) {
    handleError(err);
  }
}

function* directorReportRequest(action) {
  try {
    const { directorId, dateFrom, dateTo } = action.payload;

    const response = yield axios.get(
      `${API_URL}/directors/report?id=${directorId}&dateFrom=${dateFrom}&dateTo=${dateTo}`,
    );
    yield put(directorReportSuccess(response.data));
  } catch (err) {
    handleError(err);
  }
}

function* watchRequests() {
  yield takeEvery(DIRECTORS_REQUEST, directorsRequest);
  yield takeEvery(CREATE_DIRECTOR_REQUEST, createDirectorRequest);
  yield takeEvery(DELETE_DIRECTOR_REQUEST, deleteDirectorRequest);
  yield takeEvery(DIRECTOR_REPORT_REQUEST, directorReportRequest);
}

function* directorsSaga() {
  yield all([fork(watchRequests)]);
}

export default directorsSaga;
