export const initialState = {
  user: {},
  isAuthentificated: false,
  isLoaded: false,
  authFailure: false,
  balance: 0,
  preloader: false,
  championLogin: 'Не назначен',
  championBalance: 0,
}

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case "@@auth/LOGIN_SUCCESS":
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload.user,
        },
        isAuthentificated: true,
        isLoaded: true,
        authFailure: false,
        preloader: false,
        championBalance: action.payload.balance,
        championLogin: action.payload.cabinetLogin,
      };

    case "@@auth/SIGNUP_SUCCESS":
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload.user,
        },
        isAuthentificated: true,
        isLoaded: true,
        authFailure: false
      };

    case "@@auth/LOGOUT_SUCCESS":
      return {
        ...state,
        user: null,
        isAuthentificated: false,
        isLoaded: false,
        authFailure: false
      };
      case "@@auth/PROFILE_SUCCESS":
        return {
          ...state,
        championBalance: action.payload.balance,
        championLogin: action.payload.cabinetLogin,
        preloader: false,
      };
    case "@@auth/PROFILE_REQUEST":
        return {
          ...state,
        preloader: true,
      };
    case "@@auth/PROFILE_ERROR":
      return {
        ...state,
      preloader: false,
    };
    default: {
      return state;
    }
  }

}

export default authReducer;