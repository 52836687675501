import axios from "axios";
import { all, fork, put, takeEvery } from "redux-saga/effects";
import { settingsSuccess, updateSettingsSuccess } from "./actions";
import { SETTINGS_REQUEST, UPDATE_SETTINGS_REQUEST } from "./types";
import { API_URL } from '../../config';
import handleError from '../../helpers/errorHandler';

function* settingsRequest() {
  try {
    const response = yield axios.get(`${API_URL}/settings/`)
    yield put(settingsSuccess(response.data));
  } catch (err) {
    handleError(err);
  }
}

function* updateSettingsRequest(action) {
  try {
    const response = yield axios.post(`${API_URL}/settings/`, action.payload)
    yield put(updateSettingsSuccess(response.data));
  } catch (err) {
    handleError(err);
  }
}


function* watchRequests() {
  yield takeEvery(SETTINGS_REQUEST, settingsRequest);
  yield takeEvery(UPDATE_SETTINGS_REQUEST, updateSettingsRequest);
}

function* settingsSaga() {
  yield all([fork(watchRequests)]);
}

export default settingsSaga;