import React from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";

const PrivateRoute = ({ children, user, isAuthentificated, ...rest }) => (
  <Route
    {...rest}
    render={() => {
      return (localStorage.ACCESS_TOKEN && isAuthentificated === true) ? children : <Redirect to="/login" />
    }}
  />
);

export default connect(
  state => ({
    isAuthentificated: state.auth.isAuthentificated,
    user: state.auth.user
  }),
  {}
)(PrivateRoute);
