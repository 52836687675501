export const initialState = {
  users: {
    data: [],
    loading: false,
    totalCount: 0
  },
  currentUser: null,
}

const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case "@@user/USERS_REQUEST":
      return {
        ...state,
        users: {
          ...state.users,
          loading: true,
        }
      };
    case "@@user/USERS_ERROR":
      return {
        ...state,
        users: {
          ...state.users,
          loading: false,
        }
      };
    case "@@user/USERS_SUCCESS":
      return {
        ...state,
        users: {
          data: action.payload,
          totalCount: action.payload.length,
          loading: false,
        }
      };

    case "@@user/USER_SUCCESS":
      return {
        ...state,
        currentUser: action.payload
      };
    case "@@user/CLOSE_UPDATING":
    return {
      ...state,
      currentUser: null
    };
    case "@@user/CREATE_USER_SUCCESS":
      return {
        ...state,
        users: {
          ...state.users,
          data: [
            action.payload,
            ...state.users.data
          ],
        }
      };
    case "@@user/UPDATE_USER_SUCCESS":

    const newUsers = state.users.data.map( user => user.id === action.payload.id ? action.payload : user);
    return {
      ...state,
      users: {
        ...state.users,
        data: newUsers
      }
    };
    case "@@user/DELETE_USER_SUCCESS":
      return {
        ...state,
        users: {
          ...state.users,
          data: state.users.data.filter(user => user.id !== action.payload),
          totalCount: state.users.totalCount - 1,
        },
      };
    default: {
      return state;
    }
  }

}

export default usersReducer;