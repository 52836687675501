export const SPENDINGS_REQUEST = "@@spendings/SPENDINGS_REQUEST";
export const SPENDINGS_ERROR = "@@spendings/SPENDINGS_ERROR";
export const SPENDINGS_SUCCESS = "@@spendings/SPENDINGS_SUCCESS";

export const CREATE_SPENDING_REQUEST = "@@spendings/CREATE_SPENDING_REQUEST";
export const CREATE_SPENDING_ERROR = "@@spendings/CREATE_SPENDING_ERROR";
export const CREATE_SPENDING_SUCCESS = "@@spendings/CREATE_SPENDING_SUCCESS";

export const SPENDINGS_REPORT_REQUEST = "@@spendings/SPENDINGS_REPORT_REQUEST";
export const SPENDINGS_REPORT_ERROR = "@@spendings/SPENDINGS_REPORT_ERROR";
export const SPENDINGS_REPORT_SUCCESS = "@@spendings/SPENDINGS_REPORT_SUCCESS";