import * as types from "./types"

export const directorsRequest = (payload) => ({ type: types.DIRECTORS_REQUEST, payload })
export const directorsError = (payload) => ({ type: types.DIRECTORS_ERROR, payload })
export const directorsSuccess = (payload) => ({ type: types.DIRECTORS_SUCCESS, payload })

export const createDirectorRequest = (payload) => ({ type: types.CREATE_DIRECTOR_REQUEST, payload });
export const createDirectorSuccess = (payload) => ({ type: types.CREATE_DIRECTOR_SUCCESS, payload });

export const deleteDirectorRequest = (payload) => ({ type: types.DELETE_DIRECTOR_REQUEST, payload });
export const deleteDirectorSuccess = (payload) => ({ type: types.DELETE_DIRECTOR_SUCCESS, payload });

export const directorReportRequest = (payload) => ({ type: types.DIRECTOR_REPORT_REQUEST, payload });
export const directorReportSuccess = (payload) => ({ type: types.DIRECTOR_REPORT_SUCCESS, payload });
