export const initialState = {
  spendings: {
    data: [],
    loading: false,
    totalCount: 0,
  },
  report: {
    data: [],
    totalAmount: 0,
  }
}

const spendingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "@@spendings/SPENDINGS_REQUEST":
      return {
        ...state,
        spendings: {
          ...state.spendings,
          loading: true,
        }
      };
    case "@@spendings/SPENDINGS_SUCCESS":
      return {
        ...state,
        spendings: {
          data: action.payload.data,
          totalCount: action.payload.totalCount,
          loading: false
        }
      };
    case "@@spendings/CREATE_SPENDING_SUCCESS":
      return {
        ...state,
        spendings: {
          ...state.spendings,
          data: [
            action.payload,
            ...state.spendings.data
          ]
        }
      };
    case "@@spendings/SPENDINGS_REPORT_SUCCESS":
      return {
        ...state,
        report: {
          data: action.payload.categoryAmounts || [],
          totalAmount: action.payload.totalAmount> 0 ? action.payload.totalAmount : 0,
        }
      } 
    default: {
      return state;
    }
  }

}

export default spendingsReducer;