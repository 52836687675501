import React from "react";
import { Switch } from "react-router-dom";
import PublicRoute from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";
import Login from "src/views/pages/login/Login";
import Signup from "src/views/singup/Signup";

const TheLayout = React.lazy(() => import('../containers/TheLayout'));

const RenderRoutes = () => {
  return (
    <Switch>
      <PublicRoute exact path="/login">
        <Login />
      </PublicRoute>

      <PublicRoute exact path="/signup">
        <Signup />
      </PublicRoute>

      <PrivateRoute path="/">
        <TheLayout />
      </PrivateRoute>

    </Switch >
  );
}

export default RenderRoutes;