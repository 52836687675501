export const LOGIN_REQUEST = "@@auth/LOGIN_REQUEST";
export const LOGIN_SUCCESS = "@@auth/LOGIN_SUCCESS";

export const LOGOUT_REQUEST = "@@auth/LOGOUT_REQUEST";
export const LOGOUT_SUCCESS = "@@auth/LOGOUT_SUCCESS";

export const SIGNUP_REQUEST = "@@auth/SIGNUP_REQUEST";
export const SIGNUP_SUCCESS = "@@auth/SIGNUP_SUCCESS";

export const CHANGE_PASSWORD_REQUEST = "@@auth/CHANGE_PASSWORD_REQUEST";
export const CHANGE_PASSWORD_SUCCESS = "@@auth/CHANGE_PASSWORD_SUCCESS";

export const PROFILE_REQUEST = "@@auth/PROFILE_REQUEST";
export const PROFILE_SUCCESS = "@@auth/PROFILE_SUCCESS";
export const PROFILE_ERROR = "@@auth/PROFILE_ERROR";