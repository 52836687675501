export const initialState = {
  invoices: {
    data: [],
    totalCount: 0,
    loading: false,
  },
  totalAmount: 0,
  currentInvoice: null
}

const invoicesReducer = (state = initialState, action) => {
  switch (action.type) {
    case "@@invoice/INVOICES_REQUEST":
      return {
        ...state,
        invoices: {
          ...state.invoices,
          loading: true,
        }
      };
    case "@@invoice/INVOICES_REQUEST_ERROR":
      return {
        ...state,
        invoices: {
          ...state.invoices,
          loading: false,
        }
      };
    case "@@invoice/INVOICES_SUCCESS":
      return {
        ...state,
        invoices: {
          data: action.payload.data,
          totalCount: action.payload.totalCount,
          loading: false,
        }
      };
    case "@@invoice/INVOICES_REPORT_REQUEST":
      return {
        ...state,
        invoices: {
          ...state.invoices,
          loading: true,
        }
      };
    case "@@invoice/INVOICES_REPORT_ERROR":
      return {
        ...state,
        invoices: {
          ...state.invoices,
          loading: false,
        }
      };
    case "@@invoice/INVOICES_REPORT_SUCCESS":
      return {
        ...state,
        invoices: {
          data: action.payload.data,
          totalCount: action.payload.totalCount,
          loading: false,
        },
        totalAmount: action.payload.data.reduce((accumulator, item) => (accumulator + item.amount), 0)
      };

    case "@@invoice/INVOICE_SUCCESS":
      return {
        ...state,
        currentInvoice: action.payload
      };
    case "@@invoice/UPDATE_INVOICE_SUCCESS":
      return {
        ...state,
        currentInvoice: {
          ...state.currentInvoice,
          amount: action.payload.amount / 100
        }
      };
    case "@@invoice/CONFIRM_INVOICE_SUCCESS":
      return {
        ...state,
        currentInvoice: {
          ...state.currentInvoice,
          status: action.payload.status
        }
      };

    case "@@invoice/PROCESS_INVOICE_SUCCESS":
      return {
        ...state,
        currentInvoice: {
          ...state.currentInvoice,
          status: action.payload.status
        }
      };
    case "@@invoice/DECLINE_INVOICE_SUCCESS":
      return {
        ...state,
        currentInvoice: {
          ...state.currentInvoice,
          status: action.payload.status
        }
      };
    case "@@invoice/CREATE_INVOICE_SUCCESS":
      return state;
    default: {
      return state;
    }
  }

}

export default invoicesReducer;