import { LOGIN_REQUEST, LOGIN_SUCCESS, PROFILE_REQUEST, PROFILE_ERROR, SIGNUP_REQUEST, SIGNUP_SUCCESS, LOGOUT_REQUEST, LOGOUT_SUCCESS, PROFILE_SUCCESS, CHANGE_PASSWORD_REQUEST, CHANGE_PASSWORD_SUCCESS } from "./types"

export const loginRequest = (payload) => ({ type: LOGIN_REQUEST, payload })
export const loginSuccess = (payload) => ({ type: LOGIN_SUCCESS, payload })

export const logoutRequest = () => ({ type: LOGOUT_REQUEST })
export const logoutSuccess = () => ({ type: LOGOUT_SUCCESS })

export const signupRequest = (payload) => ({ type: SIGNUP_REQUEST, payload })
export const signupSuccess = (payload) => ({ type: SIGNUP_SUCCESS, payload })

export const changePasswordRequest = (payload) => ({ type: CHANGE_PASSWORD_REQUEST, payload })
export const changePasswordSuccess = (payload) => ({ type: CHANGE_PASSWORD_SUCCESS, payload })

export const profileRequest = (payload) => ({ type: PROFILE_REQUEST, payload })
export const profileSuccess = (payload) => ({ type: PROFILE_SUCCESS, payload })
export const profileError = () => {
  localStorage.removeItem("ACCESS_TOKEN"); return ({ type: PROFILE_ERROR });
};


