import {
  HISTORY_REQUEST,
  HISTORY_SUCCESS,
} from "./types"

export const balanceHistoryRequest = (payload) => ({ type: HISTORY_REQUEST, payload })
export const balanceHistorySuccess = (payload) => ({ type: HISTORY_SUCCESS, payload })





