import axios from "axios";
import { all, fork, put, takeEvery } from "redux-saga/effects";
import { mainStatsSuccess} from "./actions";
import { MAIN_STATS_REQUEST,  } from "./types";
import { API_URL } from '../../config';
import handleError from '../../helpers/errorHandler';

function* mainStatsRequest() {
  try {
    const response = yield axios.get(`${API_URL}/mainStats/`)
    yield put(mainStatsSuccess(response.data));
  } catch (err) {
    handleError(err);
  }
}



function* watchRequests() {
  yield takeEvery(MAIN_STATS_REQUEST, mainStatsRequest);
}

function* statsSaga() {
  yield all([fork(watchRequests)]);
}

export default statsSaga;