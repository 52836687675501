export const initialState = {
  bonuses: {
    data: [],
    totalCount: 0,
    loading: false,
  },
  totalAmount: 0,
  totalPayout: 0,
  currentBonus: null
}

const invoicesReducer = (state = initialState, action) => {
  switch (action.type) {
    case "@@bonus/BONUSES_REQUEST":
      return {
        ...state,
        bonuses: {
          ...state.bonuses,
          loading: true,
        }
      };
    case "@@bonus/BONUSES_ERROR":
      return {
        ...state,
        bonuses: {
          ...state.bonuses,
          loading: false,
        }
      };
    case "@@bonus/BONUSES_SUCCESS":
      return {
        ...state,
        bonuses: {
          data: action.payload.data,
          totalCount: action.payload.totalCount,
          loading: false,
        }
      };
    case "@@bonus/BONUSES_REPORT_REQUEST":
      return {
        ...state,
        bonuses: {
          ...state.bonuses,
          loading: true,
        }
      };
    case "@@bonus/BONUSES_REPORT_ERROR":
      return {
        ...state,
        bonuses: {
          ...state.bonuses,
          loading: false,
        }
      };
    case "@@bonus/BONUSES_REPORT_SUCCESS":
      return {
        ...state,
        bonuses: {
          data: action.payload.data,
          totalCount: action.payload.totalCount,
          loading: false,
        },
        totalAmount: action.payload.data.reduce((accumulator, item) => (accumulator + item.amount), 0),
        totalPayout: action.payload.withdrawAmount,
      };

    case "@@invoice/INVOICE_SUCCESS":
      return {
        ...state,
        currentInvoice: action.payload
      };
    case "@@invoice/UPDATE_INVOICE_SUCCESS":
      return {
        ...state,
        currentInvoice: {
          ...state.currentInvoice,
          amount: action.payload.amount / 100
        }
      };
    case "@@invoice/CONFIRM_INVOICE_SUCCESS":
      return {
        ...state,
        currentInvoice: {
          ...state.currentInvoice,
          status: action.payload.status
        }
      };

    case "@@invoice/PROCESS_INVOICE_SUCCESS":
      return {
        ...state,
        currentInvoice: {
          ...state.currentInvoice,
          status: action.payload.status
        }
      };
    case "@@invoice/DECLINE_INVOICE_SUCCESS":
      return {
        ...state,
        currentInvoice: {
          ...state.currentInvoice,
          status: action.payload.status
        }
      };
    case "@@invoice/CREATE_INVOICE_SUCCESS":
      return state;
    default: {
      return state;
    }
  }

}

export default invoicesReducer;